import React from "react";
import { Link } from "react-router-dom";

import { Paywall } from "@unlock-protocol/paywall";
import { networks } from "@unlock-protocol/networks";
import LoadingSpinner from "../../Components/Loader/spinner";

import { saveAddress, storeReferrer, getOrCreateInviteCode, logVisit } from "@sharemint/sdk";
import { prometheus } from "../../utils/utils";
const Prometheus = () => {
  const [loading, setLoading] = React.useState(true);

  

  // Configure networks to use
  // You can also use @unlock-protocol/networks for convenience...
  const networkConfigs = {
    1: {
      provider: "HTTP PROVIDER",
    },
    100: {
      // configuration for gnosis chain... etc
    },
    // etc
  };

  // Pass a provider. You can also use a provider from a library such as Magic.link or privy.io
  // If no provider is set, the library uses window.ethereum
  const provider = window.ethereum;

  const paywall = new Paywall(networks);

  React.useEffect(() => {
    storeReferrer();
    init();
    window.addEventListener("unlockProtocol.authenticated", userLogin);
    window.addEventListener("unlockProtocol.transactionSent", transactionTrigger);

  }, []);

  const userLogin=(e)=>{
    saveAddress({ slug: prometheus.slug, address:e.detail.address});
    logVisit({slug:prometheus.slug})
  }


  const transactionTrigger=(e)=>{
    saveAddress({ slug: prometheus.slug, transactionHash:e.detail.hash});
  }


  const getInviteCode=async()=>{
    const inviteCode = await getOrCreateInviteCode({ address: "0x4dDB390d8a70F1207904815B0EEaEe23b17c2210" });

    console.log(inviteCode)
  }

  const init = async () => {

    

    // set the configuration
    await paywall.setPaywallConfig(prometheus.config);

    // Loads the checkout UI
    paywall
      .loadCheckoutModal()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    loading && (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner />
      </div>
    )
  );
};

export default Prometheus;
