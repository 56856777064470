export const spark = {
  paperLink:"https://www.paper.xyz/checkout/1290a215-39e7-4297-a74f-65b15124708e",
  slug: "cfire0",
  config: {"icon":"https://storage.unlock-protocol.com/18595f8f-eebd-4c34-ad07-2629dac9c17f","locks":{"0xf2008f0ce5ee0297bc6d25b630c53519c64212ef":{"name":"Crypto is FIRE: Spark","network":137,"recipient":"","dataBuilder":"","emailRequired":true,"maxRecipients":null,"skipRecipient":false}},"title":"CFIRE DAO Membership","referrer":"","skipSelect":false,"hideSoldOut":false,"pessimistic":false,"redirectUri":"https://app.cryptoisfire.ai","messageToSign":"","skipRecipient":false,"endingCallToAction":"Click to Setup CFIRE Membership Now","persistentCheckout":false}
};

export const flame = {
  paperLink:"https://www.paper.xyz/checkout/1290a215-39e7-4297-a74f-65b15124708e",
  slug: "cfire1",
  config: {"icon":"https://storage.unlock-protocol.com/ecaca31a-e902-4d27-9cdb-57820e4e7812","locks":{"0x4296886bda6d4b26c6d11f74b61488371c1d8023":{"network":137,"name":"Crypto is FIRE: Flame","recurringPayments":2}},"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false,"redirectUri":"https://app.cryptoisfire.ai"}
};

export const lightning = {
  paperLink:"https://www.paper.xyz/checkout/24e5b04e-359a-497d-b606-e4c41fa12159",
  slug: "cfire2",
  config: {"icon":"","locks":{"0x4e1490be8cc467bf4f2f9a5ad113cc93603a7d7b":{"network":137,"name":"Crypto is FIRE: Lightning","recurringPayments":8,"maxRecipients":null,"recipient":"","dataBuilder":"","skipRecipient":false,"emailRequired":true}},"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false,"redirectUri":"https://app.cryptoisfire.ai"}
};

export const ignition = {
  paperLink:"https://www.paper.xyz/checkout/94c6c059-fffa-444a-8af8-d8628f6e71dc",
  slug: "cfire3",
  config: {"icon":"https://storage.unlock-protocol.com/cb3ee649-8fe5-42a2-b5dd-4e2773d7b8ae","locks":{"0x0cf74f3947562bdcb09dcf8e07be3aba11c7b64e":{"network":137,"name":"Crypto is FIRE: Ignition","recurringPayments":8,"maxRecipients":null,"recipient":"","dataBuilder":"","skipRecipient":false,"emailRequired":true}},"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false,"redirectUri":"https://app.cryptoisfire.ai"}
};

export const meteor = {
  paperLink:"https://www.paper.xyz/checkout/1290a215-39e7-4297-a74f-65b15124708e",
  slug: "cfire4",
  config: {"icon":"https://storage.unlock-protocol.com/bc353f88-12de-4340-9d5f-b24e50013015","locks":{"0xd4b09c2f2400de56f5c67604cdf3f596493ff012":{"network":137,"name":"Crypto is FIRE: Meteor","recurringPayments":8,"maxRecipients":null,"recipient":"","dataBuilder":"","skipRecipient":false,"emailRequired":true}},"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false,"redirectUri":"https://app.cryptoisfire.ai"}
};

export const supernova = {
  paperLink:"https://www.paper.xyz/checkout/1290a215-39e7-4297-a74f-65b15124708e",
  slug: "cfire5",
  config: {"icon":"https://storage.unlock-protocol.com/576f403a-c42f-444c-a70d-d2fde6d4319c","locks":{"0x15f0783cf122b4d3460ffea566b02fe918c2e123":{"network":137,"name":"Crypto is FIRE: Supernova","recurringPayments":8,"maxRecipients":null,"recipient":"","dataBuilder":"","skipRecipient":false,"emailRequired":true}},"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","redirectUri":"https://app.cryptoisfire.ai","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false}
};

export const prometheus = {
  paperLink:"https://www.paper.xyz/checkout/1290a215-39e7-4297-a74f-65b15124708e",
  slug: "cfire6",
  config: {"icon":"https://storage.unlock-protocol.com/ff6ec3ce-fd1e-48d6-b057-93350a5a8e3e","locks":{"0xce6aad99503dea12a7772aece47009de54c861c2":{"network":137,"name":"Crypto is FIRE: Prometheus"}},"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false,"redirectUri":"https://app.cryptoisfire.ai"}

};

export const allProducts = {
  paperLink:"https://www.paper.xyz/checkout/1290a215-39e7-4297-a74f-65b15124708e",
  slug: "cfire",
  config: {
    icon: "https://storage.unlock-protocol.com/f1c350f6-cde6-4537-b661-4286b8523258",
    locks: {
      "0x70aef1200bfc5f7871d690bb181805e69caaabcd": {
        network: 1,
        name: "Crypto is FIRE: Flame",
        pessimistic: false,
        skipRecipient: false,
        title: "Crypto is F.I.R.E. Membership",
        endingCallToAction: "Click to Login to FIRE Membership Now",
        referrer: "",
        messageToSign: "",
        persistentCheckout: false,
        hideSoldOut: false,
        skipSelect: false,
        redirectUri: "https://cryptoisfire.ai",
      },

      "0x1b066020a29fb45d24bb13bffb4cbb3e0b0cd706": {
        network: 1,
        name: "Crypto is FIRE: Lightning",
        pessimistic: false,
        skipRecipient: false,
        title: "Crypto is F.I.R.E. Membership",
        endingCallToAction: "Click to Login to FIRE Membership Now",
        referrer: "",
        messageToSign: "",
        persistentCheckout: false,
        hideSoldOut: false,
        skipSelect: false,
        redirectUri: "https://cryptoisfire.ai",
      },

      "0x7459211b12c04e8060871e845498c87281df4532": {
        network: 1,
        name: "Crypto is FIRE: Ignition",
        pessimistic: false,
        skipRecipient: false,
        title: "Crypto is F.I.R.E. Membership",
        endingCallToAction: "Click to Login to FIRE Membership Now",
        referrer: "",
        messageToSign: "",
        persistentCheckout: false,
        hideSoldOut: false,
        skipSelect: false,
        redirectUri: "https://cryptoisfire.ai",
      },

      "0x66e8de66efd78634b240daf58bfc36d0cfa4cae4": {
        name: "Crypto is FIRE: Meteor",
        network: 1,
        title: "Crypto is F.I.R.E. Membership",
        referrer: "",
        skipSelect: false,
        hideSoldOut: false,
        pessimistic: false,
        redirectUri: "https://cryptoisfire.ai",
        messageToSign: "",
        skipRecipient: false,
        endingCallToAction: "Click to Login to FIRE Membership Now",
        persistentCheckout: false,
      },

      "0xc7da35fa34d283aa01e3bc9095392ce4d2a5d66b": {
        network: 1,
        name: "Crypto is FIRE: Supernova",
        pessimistic: false,
        skipRecipient: false,
        title: "Crypto is F.I.R.E. Membership",
        endingCallToAction: "Click to Login to FIRE Membership Now",
        referrer: "",
        messageToSign: "",
        persistentCheckout: false,
        hideSoldOut: false,
        skipSelect: false,
        redirectUri: "https://cryptoisfire.ai",
      },
    },
    title: "Crypto is F.I.R.E. Membership",
    pessimistic: true,
    redirectUri: "https://app.cryptoisfire.ai",
    skipRecipient: true,
    endingCallToAction: "Click to Login to FIRE Membership Now",
  },
};

export const allProductsCustomOrder = {
  paperLink:"https://www.paper.xyz/checkout/1290a215-39e7-4297-a74f-65b15124708e",
  slug: "cfire",
  config: {
    icon: "https://storage.unlock-protocol.com/f1c350f6-cde6-4537-b661-4286b8523258",
    locks: {
      "0x0cf74f3947562bdcb09dcf8e07be3aba11c7b64e":{
        "network":137,
        "name":"Crypto is FIRE: Ignition",
        "recurringPayments":8,"maxRecipients":null,"recipient":"","dataBuilder":"","skipRecipient":false,"emailRequired":true,"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false,"redirectUri":"https://app.cryptoisfire.ai"},

     "0xd4b09c2f2400de56f5c67604cdf3f596493ff012":{"network":137,"name":"Crypto is FIRE: Meteor","recurringPayments":8,"maxRecipients":null,"recipient":"","dataBuilder":"","skipRecipient":false,"emailRequired":true,"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false,"redirectUri":"https://app.cryptoisfire.ai"},
     "0x15f0783cf122b4d3460ffea566b02fe918c2e123":{"network":137,"name":"Crypto is FIRE: Supernova","recurringPayments":8,"maxRecipients":null,"recipient":"","dataBuilder":"","skipRecipient":false,"emailRequired":true,"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","redirectUri":"https://app.cryptoisfire.ai","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false},
      
     "0x4e1490be8cc467bf4f2f9a5ad113cc93603a7d7b":{"network":137,"name":"Crypto is FIRE: Lightning","recurringPayments":8,"maxRecipients":null,"recipient":"","dataBuilder":"","skipRecipient":false,"emailRequired":true,"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false,"redirectUri":"https://app.cryptoisfire.ai"},

     "0x4296886bda6d4b26c6d11f74b61488371c1d8023":{"network":137,"name":"Crypto is FIRE: Flame","recurringPayments":2,"pessimistic":false,"skipRecipient":false,"title":"CFIRE DAO Membership","endingCallToAction":"Click to Setup Your CFIRE Membership","referrer":"","messageToSign":"","persistentCheckout":false,"hideSoldOut":false,"skipSelect":false,"redirectUri":"https://app.cryptoisfire.ai"},
    },
    title: "Crypto is F.I.R.E. Membership",
    pessimistic: true,
    redirectUri: "https://app.cryptoisfire.ai",
    skipRecipient: true,
    endingCallToAction: "Click to Login to FIRE Membership Now",
  },
};
