import "./App.css";
import { Route, Routes } from "react-router-dom";
import Spark from "./Pages/Spark/spark";
import Flame from "./Pages/Flame/flame";
import Lightning from "./Pages/Lightning/lightning";
import Ignition from "./Pages/Ignition/ignition";
import Meteor from "./Pages/Meteor/meteor";
import NotFound from "./Pages/NotFound/NotFound.jsx"; 
import Supernova from "./Pages/Supernova/supernova";
import Prometheus from "./Pages/Prometheus/prometheus";
import AllProducts from "./Pages/AllProducts/allProducts";
import CustomOrder from "./Pages/CustomOrder/customOrder";
function App() {

  return (
    <div className="App">
      {/* navbar */}
      <Routes>
        <Route exact path="/" element={<CustomOrder />} />
        <Route  path="/all" element={<AllProducts />} />
        <Route path="/spark" element={<Spark />} />
        <Route path="/flame" element={<Flame />} />
        <Route path="/lightning" element={<Lightning />} />
        <Route path="/ignition" element={<Ignition />} />
        <Route path="/meteor" element={<Meteor />} />
        <Route path="/supernova" element={<Supernova />} />
        <Route path="/prometheus" element={<Prometheus />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* Footer */}
    </div>
  );
}

export default App;
